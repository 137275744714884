@use 'variables';
.page-news {
    padding-top: 40px;

    &__hero {
        &__categorys {
            display: flex;
            padding-top: 20px;
            overflow-y: hidden;
            overflow-x: scroll;
            align-items: center;
            .category__link {
                color: #000;
                padding: 5px 14px;
                background-color: #fff;
                border: 1px solid #e2e2e2;
                border-radius: 4px;
                margin-right: 15px;
                transition: all 0.1s;
                position: relative;
                font-size: 14px;
                cursor: pointer;
                white-space: nowrap;
                @include variables.bold-font;
                &.active {
                    color: #fff;
                    background-color: #000;
                    border: 1px solid #000;
                }
            }
            .category__link__reset {
                svg {
                    width: 25px;
                    height: 25px;
                    margin-right: 5px;
                    cursor: pointer;
                    .st0 {
                        fill: #f44336;
                    }
                    .st1 {
                        fill: #fafafa;
                    }
                }
            }
            &::-webkit-scrollbar {
                display: none;
            }

            @include variables.for-tablet-landscape-up {
                overflow-x: hidden;
            }
        }
    }

    &__list {
        margin: 60px -10px 0px -10px;
        &__categorys {
            display: flex;
            padding: 0px 15px;
            padding-bottom: 30px;
            overflow-y: hidden;
            overflow-x: scroll;
            align-items: center;
            @include variables.for-mediumUp-only {
                justify-content: flex-end;
            }
            .category__link {
                color: #000;
                text-transform: uppercase;
                padding: 4px 20px 3px 10px;
                transition: all 0.1s;
                position: relative;
                font-size: 18px;
                cursor: pointer;
                @include variables.bold-font;
                &:active {
                    color: #959595;
                    transition: all 0.1s;
                }
                &::before {
                    position: absolute;
                    content: '/';
                    width: 10px;
                    height: 10px;
                    color: #000;
                    right: 0px;
                }
                &:first-child {
                    padding-left: 0px;
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                    @include variables.for-mediumUp-only {
                        padding-right: 0px;
                    }
                }
                &.active {
                    color: #959595;
                }
            }
            .category__link__reset {
                svg {
                    width: 25px;
                    height: 25px;
                    margin-right: 5px;
                    cursor: pointer;
                    .st0 {
                        fill: #f44336;
                    }
                    .st1 {
                        fill: #fafafa;
                    }
                }
            }
            &::-webkit-scrollbar {
                display: none;
            }
        }
        &__items {
            @include variables.for-mediumUp-only {
                display: flex;
                flex-wrap: wrap;
            }
            .component-news-item {
                @include variables.for-desktop-up {
                    &:nth-child(4) {
                        flex: 0 1 50%;
                        position: relative;

                        .component-news-item {
                            &__image {
                                position: relative;
                                margin-bottom: 0px;
                                &::before {
                                    position: absolute;
                                    content: '';
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    display: block;
                                    background-color: #000;
                                    opacity: 0.2;
                                    border-radius: 10px;
                                    z-index: 1;
                                }
                            }
                            &__info {
                                position: absolute;
                                margin-left: 30px;
                                bottom: 0;
                                z-index: 2;
                                &__title {
                                    color: #fff;
                                    margin-bottom: 30px;
                                }
                                &__preview {
                                    display: none;
                                }
                                &__date {
                                    color: #fff;
                                    span {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(5) {
                        flex: 0 1 50%;
                        position: relative;
                        .component-news-item {
                            &__image {
                                position: relative;
                                margin-bottom: 0px;
                                &::before {
                                    position: absolute;
                                    content: '';
                                    bottom: 0;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    display: block;
                                    background-color: #000;
                                    opacity: 0.2;
                                    border-radius: 10px;
                                    z-index: 1;
                                }
                            }
                            &__info {
                                position: absolute;
                                margin-left: 30px;
                                bottom: 0;
                                z-index: 2;
                                &__title {
                                    color: #fff;
                                    margin-bottom: 30px;
                                }
                                &__preview {
                                    display: none;
                                }
                                &__date {
                                    color: #fff;
                                    span {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .page-news__pagination {
            display: flex;
            align-items: center;
            padding: 0px 10px;
            overflow-x: scroll;
            @include variables.bold-font;
            &__link {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 45px;
                height: 45px;
                font-size: 18px;
                background-color: #e2e2e2;
                margin: 0px 5px;
                border-radius: 2px;
                outline: none;
                transition: all 0.1s;
            }

            &__item {
                &.selected {
                    .page-news__pagination__link {
                        color: #fff;
                        background-color: #000;
                    }
                }
                &:hover {
                    .page-news__pagination__link {
                        color: #fff;
                        background-color: #000;
                        transition: all 0.1s;
                    }
                }
            }

            .break {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 45px;
                height: 45px;
                font-size: 18px;
                background-color: #e2e2e2;
                margin: 0px 5px;
                border-radius: 2px;
                outline: none;
            }

            .next,
            .previous {
                display: none;
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__load {
        display: flex;
        justify-content: center;
        padding: 0px 4px 50px 4px;
        @include variables.for-tablet-landscape-up {
            padding-bottom: 70px;
        }
        .button {
            align-items: center;
            padding: 16px 15px;
            background-color: #fff;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            display: inline-flex;
            transition: all 0.1;
            @include variables.bold-font;
            &:hover {
                color: #fff;
                background-color: #00A053;
                transition: all 0.1s;
            }
        }
    }
}
