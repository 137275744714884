@use 'variables';

.component-errorBanner {
    position: relative;

    &__image {
        width: 100%;
        position: relative;
        display: none;
        @include variables.for-tablet-landscape-up {
            display: block;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 40px;
        @include variables.for-mediumUp-only {
            padding-top: 0px;
        }
        @include variables.for-tablet-landscape-up {
            padding-top: 100px;
        }
        .products-block {
            margin: 100px 0px 60px 0px;
            @include variables.for-tablet-landscape-up {
                margin-top: 60px;
            }
        }

        .component-latestNews {
            padding-top: 60px;
        }
    }

    &__title {
        font-size: 1.875rem;
        line-height: 2.5rem;
        max-width: 800px;
        padding: 20px;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.6);
        border-radius: 20px;
        @include variables.for-tablet-landscape-up {
            font-size: 3.125rem;
            line-height: 3.75rem;
        }
    }

    &__subtitle {
        padding-bottom: 10px;
        text-align: center;
        line-height: 24px;
        font-size: 16px;
    }

    &__buttons {
        margin: 10px -10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &__item {
            padding: 16px 15px;
            background-color: #00A053;
            border: 1px solid #00A053;
            border-radius: 4px;
            display: inline-flex;
            color: #fff;
            margin: 10px;
            flex: 0 0 100%;
            justify-content: center;
            @include variables.bold-font;
            @include variables.for-mediumUp-only {
                flex: 0 0 auto;
            }

            &:hover {
                color: #000;
                background-color: #fff;
                transition: all 0.1s;
                border: 1px solid #e2e2e2;
            }
        }
    }
}
