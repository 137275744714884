@use 'variables';
.component-news-item {
    padding: 0px 10px 0px 10px;
    display: flex;
    margin-bottom: 40px;
    flex-direction: column;
    flex: 0 0 100%;

    &.checked {
        &:hover {
            .component-news-item__image {
                &::after {
                    border: 2px solid #7bd762;
                }
            }
        }
        .component-news-item__image {
            &::before {
                visibility: visible;
                border-bottom: 3px solid #7bd762;
                border-right: 3px solid #7bd762;
            }
            &::after {
                border: 2px solid #7bd762;
            }
        }
    }

    &__image {
        margin-bottom: 25px;
        overflow: hidden;
        border-radius: 10px;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        img {
            transition: all 0.3s;
            object-fit: cover;
        }
    }

    &__info {
        &__date {
            font-size: 14px;
            color: #959595;
            padding-bottom: 12px;
            @include variables.regular-font;
            span {
                padding-left: 15px;
                margin-left: 5px;
                color: #000;
                font-size: 14px;
                position: relative;
                @include variables.regular-font;
                &:first-child {
                    margin-left: 0px;
                    &::before {
                        position: absolute;
                        content: '';
                        width: 10px;
                        height: 10px;
                        left: 0px;
                    }
                }
                &::before {
                    position: absolute;
                    content: '/';
                    width: 10px;
                    height: 10px;
                    left: 0px;
                }
            }
        }
        &__title {
            line-height: 30px;
            font-size: 20px;
            margin-bottom: 15px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @include variables.bold-font;
        }

        &__preview {
            color: #959595;
            line-height: 27px;
            @include variables.regular-font;
        }
    }

    &:hover {
        .component-news-item__image {
            img {
                transform: scale(1.05);
                transition: all 0.3s;
            }
        }
    }
    @include variables.for-mediumUp-only {
        box-sizing: border-box;
        flex: 0 1 50%;
    }

    @include variables.for-desktop-up {
        flex: 0 1 33.33%;
        margin-bottom: 80px;
    }
}
